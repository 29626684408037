import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";

const Preview = ({ type, target }) => {
    const [url, setUrl] = useState("");
    useEffect(() => {
        if (target && target.includes('https://')) {
            setUrl(target);
        } else if(target && target.includes('ipfs://ipfs/')){
            setUrl(`${process.env.REACT_APP_IPFS_HOST}${target.slice(12)}`);
        }
    }, [target]);

    return (
        <Box sx={{ width: "100%" }}>
            {url && (
                <Box style={{ maxHeight: "65vh" }}>
                    {type?.includes("video") ? (
                        <video
                            autoPlay
                            src={url}
                            width="100%"
                            height="auto"
                            controls
                        />
                    ) : type?.includes("audio") ? (
                        <audio
                            style={{
                                display: "block",
                                width: "100%",
                                minWidth: "310px",
                                borderRadius: "none",
                            }}
                            autoPlay
                            src={url}
                            controls
                        />
                    ) : type?.includes("image") ? (
                        <Box
                            sx={{
                                maxHeight: "inherit",
                                width: "100%",
                                height: "auto",
                            }}
                            component="img"
                            src={url}
                        />
                    ) : type?.includes("pdf") ? (
                        <iframe
                            src={url}
                            style={{ width: "100%", height: "70vh" }}
                            frameBorder="0"
                            title="PDF viewer"
                        >
                            Ваш браузер не поддерживает фреймы
                        </iframe>
                    ) : type?.includes("msword") ? (
                        <iframe
                            src={`https://docs.google.com/viewer?url=${url}
                        &embedded=true `}
                            style={{ width: "100%", height: "70vh" }}
                            frameBorder="0"
                            title="Docs previewer"
                        >
                            Ваш браузер не поддерживает фреймы
                        </iframe>
                    ) : type?.includes("table") ? (
                        <iframe
                            src={`https://docs.google.com/viewer?url=${url}&embedded=true `}
                            style={{ width: "100%", height: "70vh" }}
                            frameBorder="0"
                            title="Table previewer"
                        >
                            Ваш браузер не поддерживает фреймы
                        </iframe>
                    ) : null}
                </Box>
            )}
        </Box>
    );
};

export default Preview;

{
    /* <iframe
    src={`https://docs.google.com/viewer?url=${url}&embedded=true `}
    style={{ width: '600px', height: '600px' }}
    frameBorder="0"
    title="Doc"
>
    Ваш браузер не поддерживает фреймы
</iframe>; */
}
