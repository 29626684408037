import React, { useState, useEffect } from "react";

import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";

import api from "../api/api";
import abiERC721 from "../utils/abi/abiERC721.json";
import abiERC1155 from "../utils/abi/abiERC1155.json";

import { withRouter } from "react-router-dom";

import { Box, Paper, Typography } from "@mui/material";
import { WithoutAuth } from "./WithoutAuth";
import Preview from "./Preview";

function MainPage({
    match: {
        params: { linkId },
    },
}) {
    const { account, library, chainId } = useWeb3React();

    const [link, setLink] = useState("");
    const [linkYoutube, setLinkYoutube] = useState(null);
    const [spacesLink, setSpacesLink] = useState(null);
    const [contentType, setContentType] = useState("text");
    const [amount, setAmount] = useState(0);
    const [typeContract, setTypeContract] = useState("");
    const [userAccount, setUserAccount] = useState(null);

    const checkLink = async (hash) => {
        const unlockResponce = await api.get("/unlock/" + hash);
        return unlockResponce.data;
    };
    const getLink = async (hash) => {
        const responce = await api.get("/unlock/true/" + hash);
        const {
            data: { digitalKey, linkSpaces, contentType },
        } = responce;
        setLink(digitalKey);
        setSpacesLink(digitalKey);
        setContentType(contentType);
    };

    useEffect(() => {
        console.log("Link", link);
        if (
            link &&
            (link.includes("https://youtube") ||
                link.includes("https://www.youtube"))
        ) {
            const youtubeLinkFormat = link.includes("watch?v=")
                ? "watch"
                : link.includes("/embed/")
                ? "embed"
                : link.includes("/shorts/")
                ? "shorts"
                : null;

            let youtubeKey;
            switch (youtubeLinkFormat) {
                case "watch":
                    youtubeKey = link.split("watch?v=").at(-1);
                    break;
                case "embed":
                    youtubeKey = link.split("/embed/").at(-1);
                    break;
                case "shorts":
                    youtubeKey = link.split("/shorts/").at(-1);
                    break;

                default:
                    break;
            }
            setLinkYoutube(youtubeKey);
        }
        return () => {
            setLinkYoutube(null);
        };
    }, [link]);

    const getContract = async () => {
        const { contractAddress, tokenID, type, creator } = await checkLink(
            linkId
        );
        if (creator === account) {
            console.log("Owned");
            await getLink(linkId);
            return;
        }
        let signer;
        if (library) {
            signer = await library.getSigner();
        }
        if (account && signer) {
            let abi = type === "ERC721" ? abiERC721 : abiERC1155;
            const contract = new ethers.Contract(contractAddress, abi, signer);
            if (type === "ERC721") {
                setTypeContract(type);
                const owner = await contract.ownerOf(tokenID);
                if (owner === account) {
                    await getLink(linkId);
                } else {
                    setLink("--=Have not link for you=--");
                }
            } else if (type === "ERC1155") {
                setTypeContract(type);
                let balanceOf = await contract.balanceOf(account, tokenID);
                balanceOf = Number.parseInt(balanceOf);
                if (balanceOf !== 0) {
                    await getLink(linkId);
                    setAmount(balanceOf);
                } else {
                    setLink("--=Have not link for you=--");
                }
            }
        }
    };

    useEffect(() => {
        if (account) {
            setUserAccount(account);
            getContract();
        }
    }, [account, library, chainId]);

    const getGatewayPinataLink = (link) => {
        let gatewayLink;
        if (link.includes("ipfs://ipfs")) {
            gatewayLink = process.env.REACT_APP_IPFS_HOST + link.slice(12);
        }
        return gatewayLink;
    };

    return (
        <Box sx={{ minHeight: "78vh", margin: "0 auto" }} maxWidth="md">
            {!userAccount ? (
                <WithoutAuth />
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}
                >
                    <Paper sx={{ padding: "2rem", borderRadius: "1rem" }}>
                        <Typography
                            sx={{
                                width: "100%",
                                textAlign: "center",
                                mb: "2rem",
                            }}
                            component="h3"
                        >
                            Your account:{" "}
                            <Typography sx={{ color: "blue" }} component="span">
                                {account}
                            </Typography>
                        </Typography>
                        <Typography
                            sx={{
                                width: "100%",
                                textAlign: "center",
                                mb: "2rem",
                            }}
                            component="h3"
                        >
                            Your digital key:{" "}
                            <Typography
                                sx={{ color: "blue", fontWeight: "bold" }}
                                component="span"
                            >
                                {linkId}
                            </Typography>
                        </Typography>
                        {typeContract === "ERC1155" ? (
                            <Typography
                                sx={{
                                    width: "100%",
                                    textAlign: "center",
                                    mb: "2rem",
                                }}
                                component="h3"
                            >
                                Your have:{" "}
                                <Typography
                                    sx={{ color: "blue", fontWeight: "bold" }}
                                    component="span"
                                >
                                    {amount} token/s
                                </Typography>
                            </Typography>
                        ) : null}
                        {link ? (
                            <Typography
                                sx={{ width: "100%", textAlign: "center" }}
                                component="h3"
                            >
                                Your unlockable content:{" "}
                                {contentType !== "text" &&
                                link.includes("https://") ? (
                                    <Typography
                                        sx={{ cursor: "pointer" }}
                                        component="a"
                                        href={link}
                                    >
                                        {link}
                                    </Typography>
                                ) : link.includes("ipfs://ipfs") ? (
                                    <Typography
                                        sx={{ cursor: "pointer" }}
                                        component="a"
                                        href={getGatewayPinataLink(link)}
                                    >
                                        {getGatewayPinataLink(link)}
                                    </Typography>
                                ) : (
                                    <Typography sx={{}} component="span">
                                        {link}
                                    </Typography>
                                )}
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    width: "100%",
                                    textAlign: "center",
                                    color: "red",
                                }}
                                component="h3"
                            >
                                Link is not available. Check your connect
                                address and unlockable link
                            </Typography>
                        )}
                    </Paper>
                    {contentType !== "text" && (
                        <Paper
                            sx={{
                                padding: "2rem",
                                borderRadius: "1rem",
                                mt: "1rem",
                                maxWidth: "90%",
                            }}
                        >
                            <Preview type={contentType} target={spacesLink} />
                        </Paper>
                    )}
                    {linkYoutube && (
                        <Paper
                            sx={{
                                padding: "2rem",
                                borderRadius: "1rem",
                                mt: "1rem",
                                maxWidth: "90%",
                            }}
                        >
                            <iframe
                                title={`Yoyube link key ${linkYoutube}`}
                                width="580"
                                height="380"
                                src={`https://www.youtube.com/embed/${linkYoutube}`}
                            ></iframe>
                        </Paper>
                    )}
                </Box>
            )}
        </Box>
    );
}

export default withRouter(MainPage);
